import React, { PureComponent } from 'react';

import Sticky from 'react-sticky-el';

import Box from '../atoms/Box/Box';
import ComparisonChartHeader from '../molecules/ComparisonChartHeader';
import ComparisonChartSubscriptions from '../molecules/ComparisonChartSubscriptions';
import ComparisonChartFeature from '@components/organisms/ComparisonChartFeature';

import { Comparison } from '@shared/interfaces/comparison';
import { Subscription } from '@shared/interfaces/subscription';

type ComparisonChartProps = {
  title?: string;
  subTitle?: string;
  subscriptions?: Partial<Subscription>[]; // { name, icon, bundle }
  features: Comparison[];
};

class ComparisonChart extends PureComponent<ComparisonChartProps> {
  render() {
    const { subscriptions, features, title, subTitle } = this.props;

    const bundles = subscriptions.map(subscription =>
      subscription?.bundle?.toLowerCase()
    );
    const content = (
      <Box className="features-table-boundary">
        <Sticky
          topOffset={-50}
          bottomOffset={50}
          stickyStyle={{ zIndex: 1 }}
          boundaryElement=".features-table-boundary"
          scrollElement="#layout-wrapper"
        >
          <ComparisonChartSubscriptions tabIndex={0} subscriptions={subscriptions} />
        </Sticky>

        <Box smPt={1.75} pt={2.75} lgPt={3.125} smMb={3.375} mb={5} lgMb={7.75}>
          {features.map((feature, i) => {
            return (
              <ComparisonChartFeature
                key={i}
                feature={feature}
                bundles={bundles}
              />
            );
          })}
        </Box>
      </Box>
    );

    return (
      <Box
        pt={4} 
        maxWidth={71}
        id="features-table"
      >
        <ComparisonChartHeader title={title} subTitle={subTitle} />
        {content}
      </Box>
    );
  }
}

export default ComparisonChart;
