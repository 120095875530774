import React, { PropsWithChildren } from 'react';

import ReactDOM from 'react-dom';

import { IntersectionCallback } from '@components/organisms/Footer';

type ObserverProps = PropsWithChildren<{
  onChange: IntersectionCallback;
  rootId?: string;
  rootMargin?: string;
  threshold?: number;
}>;

class Observer extends React.Component<ObserverProps> {
  interSectionObserver = () => {
    const { onChange, rootId, rootMargin, threshold } = this.props;
    const target = ReactDOM.findDOMNode(this) as Element;

    let options = {
      root: document.querySelector(`#${rootId}`) || null,
      rootMargin: rootMargin || '0px',
      threshold: threshold || 0,
    };

    let observer = new IntersectionObserver(onChange, options); // eslint-disable-line
    observer.observe(target);
  };

  componentDidMount() {
    this.interSectionObserver();
  }

  render() {
    return this.props.children;
  }
}

export default Observer;
