export function _scrollToElement(elem: HTMLElement) {
  if (!elem) return;

  const layoutWrapper = document.querySelector('#layout-wrapper');
  const layoutContent = document.querySelector('#layout-content');

  const top =
    elem.getBoundingClientRect().top - layoutContent.getBoundingClientRect().y;
  layoutWrapper.scrollTo({
    top,
    behavior: 'smooth',
  });
}
