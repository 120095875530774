import React, { PureComponent } from 'react';

import styled, { css } from 'styled-components';

import Box from '../atoms/Box/Box';
import { Col, Row } from '../atoms/Grid';
import Text from '../atoms/Text/Text';
import NewFeature from '@components/molecules/NewFeature';
import SubtitleTooltip from '@components/molecules/SubtitleTooltip';

import CheckIcon from '@public/icons/check.svg';
import { ComparisonItemValue } from '@shared/interfaces/comparison';
import { breakpoint } from 'theme';

const HiddenText = styled.span`
  position: absolute;
  height: -1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;

const CheckIconExtend = styled(CheckIcon).attrs({
  "role": "img",
})`
  color: ${props => props.theme.colors.kuroiBlack};
  };

  ${breakpoint.m`
    width: 24px;
    height: 24px;
  `}
`;

const sharedColMy = css`
  padding-top: 0.75em;
  padding-bottom: 0.75em;
`;

const RowWrapper = styled(Row)`
  border-top: 1px solid ${props => props.theme.colors.iron};

  ${breakpoint.down('ltm')`
    border-top: none;
  `}
`;

const ColWrapper = styled(Col)`
  display: flex;
  align-items: center;
  ${sharedColMy};

  ${breakpoint.down('ltm')`
    padding-top: 1.125em;
  `}
`;

const BoxWrapper = styled(Box)`
  ${breakpoint.lg`
    display: flex;
  `}
`;

const ColValue = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  ${sharedColMy};
  border-left: 1px solid ${({ theme }) => theme.colors.iron};
  ${({ isMarked, theme }) =>
    isMarked &&
    `
    background-color: ${theme.transparentColors.turquoise10};
  `}

  ${breakpoint.down('ltm')`
    border-top: 1px solid ${({ theme }) => theme.colors.iron};
    border-bottom: 1px solid ${({ theme }) => theme.colors.iron};

    &:first-child {
      border-left: none;
    }
  `}
`;

const BoxName = styled(Box)`
  display: flex;
  align-items: center;
`;

const NotIncluded = styled.div`
  background-color: #B6BBBE;
  width: 10px;
  height: 2px;
`;

type ComparisonChartSubFeatureProps = {
  name: string;
  video?: string;
  isNew?: boolean;
  values?: ComparisonItemValue[];
  bundles: string[];
  // openVideo: (url: string) => void;
  asterisk?: boolean | string;
  icon?: string;
  subtitles?: string;
};

class ComparisonChartSubFeature extends PureComponent<ComparisonChartSubFeatureProps> {
  renderValue = (value, isMarked) => {
    switch (value) {
      case 'True':
        return (<CheckIconExtend />);
      case 'False':
        return (<NotIncluded aria-label="Not Included" />);
      default:
        return (
          <Text
            smSize={0.875}
            size={1}
            lgSize={1.125}
            color={isMarked ? 'kuroiBlack' : 'silverSand'}
            weight="bold"
            lineHeight="normal"
          >
            {value}
          </Text>
        );
    }
  };

  render() {
    const { name, video, isNew, values, bundles, asterisk, icon, subtitles } =
      this.props;

    return (
      <RowWrapper gutter={0} role="row">
        <ColWrapper xs={12} sm={6} role="rowheader" tabIndex={0}>
          <BoxWrapper smPl={1} smPr={1}>
            <BoxName>
              <Text color="kuroiBlack" smSize={1} size={1.125}>
                {`${name}${asterisk ? '*' : ''}`}
              </Text>
              {icon && <SubtitleTooltip title={subtitles} icon={icon} />}
            </BoxName>
            {isNew && <NewFeature />}
          </BoxWrapper>
        </ColWrapper>
        {bundles.map((bundle, i) => {
          const value = values.find(value => value.bundle === bundle);

          const ariaLabel = value?.value === 'True' ? 'Included' 
            : value?.value === 'False' ? 
              'Not included' : '';

          return (
            <ColValue
              key={i}
              xs={12 / bundles.length}
              sm={6 / bundles.length}
              isMarked={value?.is_marked}
              tabIndex={0}
              ariaLabel={ariaLabel}
              role="cell"
            >
              {this.renderValue(value?.value, value?.is_marked)}
            </ColValue>
          );
        })}
      </RowWrapper>
    );
  }
}

export default ComparisonChartSubFeature;
