import React, { PureComponent } from 'react';

import styled from 'styled-components';

import Image from '../atoms/Image/Image';

import SubtitlesIcon from '@public/icons/subtitles.svg';

import { breakpoint } from 'theme';

type SubtitleTooltipProps = {
  title: string;
  icon: string;
};

type SubtitleTooltipState = {
  isVisible: boolean;
};

class SubtitleTooltip extends PureComponent<SubtitleTooltipProps, SubtitleTooltipState> {
  state: SubtitleTooltipState = {
    isVisible: false,
  };

  positionBorderRef: React.RefObject<HTMLSpanElement> = React.createRef();


  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && this.positionBorderRef.current) {
      this.positionBorderRef.current.blur();
      this.setState({ isVisible: false });
      document.body.focus();
    }
  };

  handleFocus = () => {
    this.setState({ isVisible: true });
  };

  handleBlur = () => {
    this.setState({ isVisible: false });
  };

  toggleVisibility = () => {
    this.setState(prevState => ({ isVisible: !prevState.isVisible }));
  };

  render() {
    const { isVisible } = this.state;
    
    return (
      <PositionBorder
        ref={this.positionBorderRef}
        tabIndex={0}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
      >
        <StyledButton tabIndex={-1} onClick={this.toggleVisibility}>
          <SubtitlesIcon role="img" style={{ marginLeft: '0.5em' }} aria-label="subtitles" />
        </StyledButton>
        <Subtitle isVisible={isVisible}>
          <Title>{this.props.title.split(':')[0]}</Title>
          <SubtitleText>: {this.props.title.split(':')[1]}</SubtitleText>
        </Subtitle>
      </PositionBorder>
    );
  }
}

export default SubtitleTooltip;

const StyledButton = styled.button`
  background: none;
  border: none;
  padding: 0;
`

const PositionBorder = styled.span`
  position: relative;

  &:focus-within {
    outline: none;
  }
`;

const Subtitle = styled.div<{isVisible: boolean}>`
  display: ${props=> props.isVisible? 'block': 'none'};
  position: absolute;
  z-index: 1;
  left: 2.6em;
  top: -0.3em;
  ${props => `
    background-color: ${props.theme.colors.cornflowerBlue};
    color: ${props.theme.colors.white};  
  `};
  box-shadow: 0 0 0.5em 0 ${({ theme }) => theme.transparentColors.black25};
  padding: 0.4em 0.875em 0.525em;
  border-radius: 0.25em;
  min-width: 15.5em;

  ${PositionBorder}:hover & {
    display: block;
  }

  :after {
    content: '';
    position: absolute;
    left: -0.75em;
    top: 30%;
    width: 0;
    height: 0;
    border-top: 0.4em solid transparent;
    border-bottom: 0.4em solid transparent;
    border-right: 0.75em solid ${props => props.theme.colors.cornflowerBlue};
    clear: both;
  }

  ${breakpoint.down('ltm')`
    left: auto;
    right: -0.8em;
    top: 2.25em;
    min-width: 10em;
    
    :after {
      left: auto;
      right: 1em;
      top: -1.2em;
      border-left: 0.5em solid transparent;
      border-right: 0.5em solid transparent;
      border-bottom: 0.75em solid ${props => props.theme.colors.cornflowerBlue};
    }
  `}
`;

const Title = styled.div`
  display: inline;
  font-weight: bold;
  font-size: 0.875em;
  white-space: nowrap;
`;

const SubtitleText = styled(Title)`
  font-weight: normal;
  white-space: nowrap;

  ${breakpoint.down('ltm')`
    white-space: pre-wrap;
  `}
`;
