import React, { PureComponent } from 'react';

import styled from 'styled-components';

import Box from '../atoms/Box/Box';
import Circle from '../atoms/Circle/Circle';
import { Col, Row } from '../atoms/Grid';
import Icon, { icons } from '../atoms/Icon';
import Loader from '../atoms/Loader/Loader';
import Text from '../atoms/Text/Text';

import { Align } from '@shared/enums/align';
import { I18nTranslation } from '@shared/interfaces/i18n';
import Observer from '@hoc/Observer';

import { breakpoint } from 'theme';
import { withTranslation } from 'i18n';

const BoxFeature = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Description = styled(Text)`
  font-size: 1em;
  color: ${props => props.theme.colors.cornflowerBlue};
  line-height: 1.5;

  a {
    color: ${props => props.theme.colors.abyss};
    text-decoration: underline;
  }

  ${breakpoint.m`
    font-size: 1.125em;
  `}
`;

type LicenseFeaturesProps = {
  t: I18nTranslation;
};

class LicenseFeatures extends PureComponent<LicenseFeaturesProps> {
  state = {
    loading: true,
  };

  t = (key, options?) => this.props.t(`features.${key}`, options);

  get features() {
    const features = this.t('features', { returnObjects: true });

    return Object.entries(features).map(
      ([id, data]: [string, Record<string, string>]): Record<
        string,
        string
      > => ({
        image: id,
        ...data,
      })
    );
  }

  handleIntersectionCallback = (
    entry: IntersectionObserverEntry[],
    observer: { unobserve: (arg0: Element) => void }
  ) => {
    const target = entry[0];
    if (target.isIntersecting) {
      this.setState({
        loading: false,
      });
      observer.unobserve(target.target);
    }
  };

  render() {
    const loader = <Loader />;
    const content = (
      <Box 
        role="region"
        ariaLabel="License Features"
        maxWidth={71} 
        smPl={1.5} 
        smPr={1.5} 
        mPl={2.75} 
        mPr={2.75}
      >
        <Box smMb={2} mb={5} align={Align.Center}>
          <Text
            smSize={1.5}
            size={2.25}
            weight="bold"
            lineHeight="normal"
            color="cornflowerBlue"
            component="h2"
            align={Align.Center}
          >
            {this.t('description')}
          </Text>
        </Box>
        <Row gutterXs={1.5} gutter={2.75} role="group" aria-label="Feature list">
          {this.features.map((feature, i) => {
            const name = feature.image as keyof typeof icons;

            return (
              <Col
                key={i}
                xs={12}
                sm={6}
                lg={4}
                smOffset={Number(feature.smOffset)}
                lgOffset={Number(feature.lgOffset)}
                role="group"
                aria-label={feature.name}
              >
                <BoxFeature smMb={3.25} mb={5.75} lgMb={6.5}>
                  <Box smMb={0.75} mb={1}>
                    <Circle backgroundColor="abyss" smSize={5.625} size={8}>
                      <Icon name={name} smSize={3} size={4.5} color="abyss" />
                    </Circle>
                  </Box>
                  <Box smMb={0.375} mb={0.625} align={Align.Center}>
                    <Text
                      smSize={1.375}
                      size={1.5}
                      color="cornflowerBlue"
                      weight="bold"
                      lineHeight="normal"
                    >
                      {feature.name}
                    </Text>
                  </Box>
                  <Box align={Align.Center}>
                    <Description>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: feature.description,
                        }}
                      />
                    </Description>
                  </Box>
                </BoxFeature>
              </Col>
            );
          })}
        </Row>
      </Box>
    );

    return (
      <Observer onChange={this.handleIntersectionCallback}>
        {this.state.loading ? loader : content}
      </Observer>
    );
  }
}

export default withTranslation('common')(LicenseFeatures);
