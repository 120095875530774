import React, { PureComponent } from 'react';

import styled from 'styled-components';

import Box from '../atoms/Box/Box';
import ExternalLink from '../atoms/ExternalLink/ExternalLink';
import Text from '../atoms/Text/Text';
import ComparisonChartSubFeature from '../molecules/ComparisonChartSubFeature';
import NewFeature from '../molecules/NewFeature';

import { Comparison } from '@shared/interfaces/comparison';
import ExternalLinkIcon from '@public/icons/externalLink.svg';
import { Target } from '@shared/enums/target';
import { breakpoint } from 'theme';

const BoxItems = styled(Box)`
  ${breakpoint.m`
    > div:last-child {
      border-bottom: 1px solid ${props => props.theme.colors.iron};
    }
  `}
`;

const FeatureExternalLink = styled(ExternalLink)`
  vertical-align: middle;
`;

const BoxFeature = styled(Box)`
  margin-bottom: 3.125em;

  &:last-child {
    margin-bottom: 0;
  }
`;

const BoxTitle = styled(Box)`
  display: flex;
  align-items: center;
`;

const ExternalLinkIconExtend = styled(ExternalLinkIcon).attrs({
  'aria-label':'External link'
})`
  margin-left: 0.5em;

  ${breakpoint.m`
    width: 20px;
    height: 20px;
  `};
`;

const Subtext = styled(Text)`
  display: flex;
  justify-content: flex-end;
`;

type ComparisonChartFeatureProps = {
  feature: Comparison;
  bundles?: string[];
};

class ComparisonChartFeature extends PureComponent<ComparisonChartFeatureProps> {
  render() {
    const { feature, bundles } = this.props;

    return (
      <BoxFeature role="table">
        <BoxTitle
          pl={1}
          pr={1}
          mPl={1.25}
          mPr={1.25}
          lgPl={2}
          lgPr={2}
          smMb={0}
          mb={0.625}
        >
          <Box>
            <Text
              smSize={1.375}
              size={1.5}
              lgSize={1.75}
              weight={600}
              lineHeight="normal"
              component="div"
              tabIndex={0}
            >
              {feature.title}
            </Text>

            {feature.description && (
              <Text color="lampPost" size={0.875} lineHeight="normal" tabIndex={0}>
                {feature.description}
              </Text>
            )}
          </Box>

          {feature.link && (
            <FeatureExternalLink 
              href={feature.link} 
              target={Target.Blank} 
              ariaLabel={feature.title + " (opens in new tab)"}
            >
              <ExternalLinkIconExtend/>
            </FeatureExternalLink>
          )}

          {feature.isNew && <NewFeature />}
        </BoxTitle>

        <BoxItems mPl={1.25} mPr={1.25} lgPl={2} lgPr={2}>
          {feature.items.map((item, i) => {
            return (
              <ComparisonChartSubFeature
                key={i}
                name={item.name}
                isNew={item.isNew}
                values={item.values}
                bundles={bundles}
                asterisk={item.asterisk}
                icon={item.icon}
                subtitles={item.subtitles}
              />
            );
          })}
        </BoxItems>
        {feature.subtext && (
          <Box mPl={1.25} mPr={1.25} lgPl={2} lgPr={2}>
            <Subtext color="grayChateau" size={0.875} lineHeight={2} tabIndex={0}>
              {feature.subtext}
            </Subtext>
          </Box>
        )}
      </BoxFeature>
    );
  }
}

export default ComparisonChartFeature;
