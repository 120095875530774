import React, { PureComponent } from 'react';

import styled from 'styled-components';

import Box from '../atoms/Box/Box';
import { Col, Row } from '../atoms/Grid';
import Image from '../atoms/Image/Image';
import Text from '../atoms/Text/Text';

import { Align } from '@shared/enums/align';
import { Bundle } from '@shared/enums/bundle';
import { I18nTranslation } from '@shared/interfaces/i18n';
import { withTranslation } from 'i18n';

const BoxWrapper = styled(Box)`
  background-image: linear-gradient(
    84deg,
    ${props => props.theme.colors.oxfordBlue},
    ${props => props.theme.colors.cornflowerBlue}
  );
`;

type Subscription = { icon: string; name: string; bundle: Bundle };
type ComparisonChartSubscriptionsProps = {
  subscriptions: Subscription[];
  t: I18nTranslation;
};

class ComparisonChartSubscriptions extends PureComponent<ComparisonChartSubscriptionsProps> {
  t = key => this.props.t(`features.${key}`);

  render() {
    const { subscriptions } = this.props;

    return (
      <BoxWrapper
        role="rowgroup"
        smPt={0.625}
        smPr={0.75}
        smPb={0.625}
        smPl={0.75}
        pt={0.75}
        pr={1.25}
        pb={0.625}
        pl={1.25}
      >
        <Row role="row" bottom="sm">
          <Col role="columnheader" xs={false} sm={6} tabIndex={0}>
            <Text size={1.125} weight="bold" lineHeight="normal" color="white">
              {this.t('title')}
            </Text>
          </Col>
            {subscriptions.map(subscription => {
              return (
                <Col 
                  role="columnheader"
                  tabIndex={0}
                  xs={12 / subscriptions.length} 
                  sm={2}
                  key={subscription.bundle}
                >
                  <Box align={Align.Center}>
                    <Image
                      width={1.875}
                      mWidth={2.5}
                      src={subscription.icon}
                    />
                  </Box>
                  <Box align={Align.Center}>
                    <Text
                      size={0.75}
                      lgSize={1.125}
                      weight="bold"
                      lineHeight="normal"
                      color="white"
                    >
                      {subscription.name}
                    </Text>
                  </Box>
                </Col>
              );
            })}
        </Row>
      </BoxWrapper>
    );
  }
}

export default withTranslation('common')(ComparisonChartSubscriptions);
